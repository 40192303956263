<template>
  <div class="login">
    <Pulse-Loader
      :loading="loading"
      style="
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100vh;
        margin-top: 25%;
      "
    ></Pulse-Loader>

    <div class="wrapper fadeInDown">
      <div id="formContent" ref="formContent">
        <!-- Tabs Titles -->

        <!-- Icon -->
        <div id="lg-mdy" class="fadeIn first">
          <img src="img/logo_klein.jpg" id="icon" alt="Lab Logo" />
        </div>

        <!-- Login Form -->
        <form @submit.prevent="login">
          <select
            v-model="userType"
            class="tipoUsuario"
            ref="userType"
            required
            @change="changePlaceholder($event)"
          >
            <option disabled value="">Tipo de usu&aacute;rio</option>
            <option value="tulPaciente">Paciente</option>
            <option value="tulApoio">Apoio</option>
            <option value="tulMedico">M&eacute;dico</option>
            <option value="tulConveniado">Conveniado</option>
            <option value="tulCliente">Cliente</option>
            <option value="tulCentralAtendimento">Central de Atendimento</option>
          </select>
          <input
            v-model="username"
            ref="username"
            type="text"
            id="login"
            class="fadeIn second"
            name="login"
            placeholder="Nº do laudo ou usuário"
            required
          />
          <input
            v-model="password"
            ref="password"
            type="password"
            id="password"
            class="fadeIn third"
            name="password"
            placeholder="Senha"
            required
          />
          <input
            type="submit"
            class="fadeIn fourth"
            value="Acessar"
            ref="acessar"
          />
        </form>

        <br />
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "Login",
  components: {
    PulseLoader,
  },
  props: {
    msg: String,
  },
  data: () => {
    return {
      userType: "",
      username: "",
      password: "",
      loading: false,
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
    acoes() {
      return this.$actions;
    },
  },
  methods: {
    async login(e) {
      e.preventDefault();
      let _this = this;
      _this.$refs.formContent.style.opacity = "0.4";
      this.loading = true;
      var username = this.$refs.username.value;
      var password = this.$refs.password.value;
      var tpLogin = this.$refs.userType.value;

      if (username == "" || username == undefined) {
        _this.$refs.formContent.style.opacity = "1";
        console.log("You must provide a valid username");
        return;
      }
      if (password == "" || password == undefined) {
        _this.$refs.formContent.style.opacity = "1";
        console.log("You must provide a valid password");
        return;
      }

      if (tpLogin == "tulPaciente") {
        if (!(username.includes('/') || username.includes('.')))
          tpLogin = "tulPacienteCpf";
      }

      await axios
        .post(
          "/v1/login?username=" +
            username +
            "&password=" +
            password +
            "&tplogin=" +
            tpLogin
        )
        .then(function (response) {
          if (response.status === 201) {
            const { code, token } = response.data;
            _this.acoes.setUser(_this.state, username);
            _this.acoes.setToken(_this.state, token);
            _this.acoes.setPassword(_this.state, password);
            _this.acoes.setLoginType(_this.state, tpLogin);
            if(tpLogin === "tulCentralAtendimento") {
              _this.acoes.setFilial(_this.state, response.data.idd);
            }
            else if (tpLogin === "tulPaciente" || tpLogin === 'tulPacienteAtendimento') {
              _this.acoes.setUserType(_this.state, 'Paciente');
              _this.$router.push('/mdy-list').catch((error) => {console.log(error)});
            }
            else if (tpLogin === "tulPacienteCpf") {
              _this.acoes.setUserID(_this.state, response.data.id);
              _this.acoes.setUserType(_this.state, 'Paciente');
              _this.$router.push('/mdy-list').catch(() => {});
            }
            else if (tpLogin === "tulMedico" || tpLogin === "tulConveniado" || tpLogin === "tulCliente") {
              _this.acoes.setUserID(_this.state, response.data.id);
              _this.$router.push("/mdy-filter");
            } else {
              _this.$router.push("/mdy-filter");
            }
          } else {
            setTimeout(() => {
              _this.$notify({
                group: "alerts",
                type: "warn",
                title: "ATENCAO",
                text: "Usuario ou senha incorretos.",
              });
              _this.$refs.formContent.style.opacity = "1";
            }, 100);
          }
        })
        .catch(function (response) {
          setTimeout(() => {
            _this.$notify({
              group: "alerts",
              type: "error",
              title: "ATENCAO",
              text: "Usuario ou senha incorretos.",
            });
            _this.$refs.formContent.style.opacity = "1";
          }, 100);
        }).finally(() => {
          this.loading = false;
        });
    },
    check: function (e) {
      if (this.$refs.validaDigital.checked) {
        //Desabilitar Campos
        this.$refs.userType.disabled = true;
        this.$refs.username.disabled = true;
        this.$refs.password.disabled = true;
        this.$refs.acessar.disabled = true;

        //Revelar Campos de Validação Digital
        this.$refs.menuValidacao.style.height = "150px";
        this.$refs.validacao.style.display = "block";
        this.$refs.validar.style.display = "block";
      } else {
        //Habilitar Campos
        this.$refs.userType.disabled = false;
        this.$refs.username.disabled = false;
        this.$refs.password.disabled = false;
        this.$refs.acessar.disabled = false;

        //Remover Campos de Validação Digital
        this.$refs.validacao.style.display = "none";
        this.$refs.validar.style.display = "none";
        this.$refs.menuValidacao.style.height = "0px";
      }
    },
    changePlaceholder(event) {
      let val = event.target.value;

      switch (val) {
        case "tulPaciente":
          this.$refs.username.placeholder = "Nº do laudo / CPF";
          break;
        case "tulMedico":
          this.$refs.username.placeholder = "Usuário";
          break;
        case "tulCliente":
          this.$refs.username.placeholder = "Usuário";
          break;
        case "tulConveniado":
          this.$refs.username.placeholder = "Usuário";
          break;
        case "tulApoio":
          this.$refs.username.placeholder = "Usuário";
          break;
        default:
          this.$refs.username.placeholder = "Nº do laudo ou usuário";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
